import React, { useEffect, useState } from "react";
const ReportComponent = (props) => {
    return (
        <>
            {
                props.powerBiRapport ?
                    <div id="reports-list">
                        <div style={{ display: 'flex', alignItems: 'center' }} key={props.powerBiRapport.reportInfos? props.powerBiRapport.reportInfos.datasetId:""}>
                            <p key={props.index} onClick={() => props.selectReport(props.powerBiRapport)}>
                                <span>{props.powerBiRapport.reportInfos? props.powerBiRapport.reportInfos.name:""}</span>
                            </p>
                        </div>
                    </div>
                    :
                    null
            }
        </>

    );
};
export default ReportComponent;
