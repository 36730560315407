import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
const LegacyDisplayReport = (props) => {
    let layout = models.LayoutType.Master
    return(
        <div className="PowerBIEmbed-parent-div">
                        <div className="PowerBIEmbed-child-div" >
                            <PowerBIEmbed
                                className="PowerBIEmbed"
                                embedConfig={{
                                    type: "report",
                                    id: props.powerBiRapport.reportInfos.id,
                                    embedUrl: props.powerBiRapport.reportInfos.embedUrl,
                                    accessToken: props.token,
                                    tokenType: models.TokenType.Embed,
                                    permissions: models.Permissions.All,
                                    settings: {
                                        panes: {
                                            filters: {
                                                expanded: true,
                                                visible: false,
                                            },
                                            pageNavigation: {
                                                visible: true,
                                            },
                                            toolbar: {
                                                visible: false,
                                            },
                                        },
                                        background: models.BackgroundType.Transparent,
                                        layoutType: layout,
                                        navContentPaneEnabled: true,
                                        disableVisualInteractions: true,
                                        personalBookmarksEnabled: true,
                                        visualHeaders: {
                                            visible: false,
                                        },
                                    },
                                }}
                                cssClassName={"report-style-class"}
                            />
                        </div>
                    </div>
    )
}

export default LegacyDisplayReport;