import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { HEEDIFY_POWERBI_SERVICES_PATH } from "../config/config"
import { models } from "powerbi-client";
import {  Menu } from "@fluentui/react-northstar";
import { isBrowser } from "react-device-detect";
import axios from "axios";
const StandardDisplayReport = (props) => {
    const [reportPages, setReportPages] = useState([]);
    const [selectedPage, setSelectedPage] = useState('ReportSection');
    const [report, setReport] = useState();

    const activePointRef = React.useRef(report);
    let layout = models.LayoutType.Master
    const { isLandscape, isPortrait } = props;
    switch (true) {
        case isLandscape === true:
            layout = 3;
            break;
        case isPortrait === true:
            layout = 2;
            break;
        case isBrowser === true:
            layout = 0;
            break;
        default:
            layout = 2;
            break;
    }
    const newSettings = {
        layoutType: layout,
    };
    const handleItemClick = (e, data) => {
        let dropdownItems = reportPages.map(page => ({
            key: page.name,
            content: page.displayName,
        }))
        setSelectedPage(dropdownItems[data.index].key)
    };
    const initReport = async () => {
        if (report) {
            setReportPages([])
            if (props.flowConfig.reportConfiguration.reportPages) {
                for (const page of props.reportInfos.flowConfig.reportPages) {
                    let pageDisplayName = page.displayName.replace("_Default", "");
                    setReportPages(reportPages =>
                        pageDisplayName.includes("Dashboard")
                            ? [{ name: page.name, displayName: pageDisplayName }, ...reportPages]
                            : [...reportPages, { name: page.name, displayName: pageDisplayName }]
                    );
                }
            } else {
                let allPages = await getReportPages(props.reportInfos.id, props.reportInfos.datasetWorkspaceId)
                for (const page of allPages) {
                    if (page.displayName.includes("_Default")) {
                        let pageDisplayName = page.displayName.replace("_Default", "");
                        setReportPages(reportPages =>
                            pageDisplayName.includes("Dashboard")
                                ? [{ name: page.name, displayName: pageDisplayName }, ...reportPages]
                                : [...reportPages, { name: page.name, displayName: pageDisplayName }]
                        );
                    }
                }
            }
            report.updateSettings(newSettings);
        }
    }
    async function getReportPages(reportId, groupId) {
        let url = HEEDIFY_POWERBI_SERVICES_PATH + "GetReportPages/" + groupId + "/" + reportId;
        try {
            const response = await axios.get(url);
            return response.data.value;

        } catch (error) {
            console.error('Error fetching report pages:', error.response ? error.response.data : error.message);
            return []
        }
    }
    useEffect(() => {
        initReport()
    }, [report])
    return(
        <>
        <Menu
            defaultActiveIndex={0}
            items={reportPages.map(page => ({
                key: page.name,
                content: page.displayName,
            }))}
            
            onItemClick={handleItemClick}
            style={{ flex: "0 0 auto" }}
        />
        <div className="PowerBIEmbed-parent-div">
            <div className="PowerBIEmbed-child-div" >
                <PowerBIEmbed
                    className="PowerBIEmbed"
                    embedConfig={{
                        pageName: selectedPage,
                        type: "report",
                        id: props.powerBiRapport.reportInfos.id,
                        embedUrl: props.powerBiRapport.reportInfos.embedUrl,
                        accessToken: props.token,
                        tokenType: models.TokenType.Embed,
                        permissions: models.Permissions.All,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: true,
                                    visible: false,
                                },
                                pageNavigation: {
                                    visible: false,
                                },
                                toolbar: {
                                    visible: false,
                                },
                            },
                            background: models.BackgroundType.Transparent,
                            layoutType: layout,
                            navContentPaneEnabled: true,
                            disableVisualInteractions: true,
                            personalBookmarksEnabled: true,
                            visualHeaders: {
                                visible: false,
                            },
                        },
                    }}
                    cssClassName={"report-style-class"}
                    eventHandlers={
                        new Map([
                            ['loaded', function () { console.log("Report Loaded"); }],
                            ['rendered', function () { console.log("Report Rendered"); }],
                            ['error', function (event) { console.log(event.detail); }]
                        ])
                    }
                    getEmbeddedComponent={function (embeddedReport) {
                        activePointRef.current = embeddedReport;
                        setReport(() => embeddedReport);
                    }}
                />
            </div>
        </div>
    </>
    )
}

export default StandardDisplayReport;