import React, { useEffect, useState } from "react";
import ReportComponent from './ReportComponent'
const ReportsListComponent = (props) => {
  let [raports, setRaports] = useState([]);
  useEffect(() => {
    if (props.reportsListData) {
      setRaports(props.reportsListData)
    }
  }, [props.reportsListData])
  return (
    <>
      {
        raports ?
          <div id="reports-list">
            {raports?.map((report, k) => (
              <ReportComponent powerBiRapport={report} index={k} key={k} selectReport={props.selectReport} />
            ))}
          </div>
          :
          null
      }
    </>

  );
};
export default ReportsListComponent;
