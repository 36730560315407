import React from "react";
import "./index.css";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { thunk } from 'redux-thunk';
import reportingReducers from "./redux/reducers";
import { authContext } from "./auth";
import {
  Provider as ProviderNorth,
  teamsTheme,
} from "@fluentui/react-northstar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Welcome from "./components/WelcomeComponent/WelcomeComponent";
import ReportsListPageContainer from "./containers/ReportsListPageContainer";
import { IntlProvider } from "react-intl";
import {
  Providers,
  SimpleProvider,
  ProviderState,
  Login
} from "@microsoft/mgt";
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;


function getAccessToken(scopes) {

  return new Promise((resolve, reject) => {
    authContext.acquireToken(
      "https://graph.microsoft.com",
      function (errDesc, token, err, tokenType) {
        if (token) {
          Providers.globalProvider.setState(ProviderState.SignedIn);
          resolve(token);
        }
      }
    );
  });
}
function login() {
  authContext.handleWindowCallback()
  authContext.login().then(() => {
    Providers.globalProvider.setState(ProviderState.SignedIn);
  });
}
function logout() {
  authContext.logOut();
}
let provider = new SimpleProvider(getAccessToken, login, logout);
Providers.globalProvider = provider;
authContext.handleWindowCallback();
let user = authContext.getCachedUser();
if (user) {
  Providers.globalProvider.setState(ProviderState.SignedIn);
}
const store = createStore(
  reportingReducers, /* preloadedState, */
  composeEnhancers(applyMiddleware(thunk)) // Apply thunk middleware
);
render(
  <Router>
    <IntlProvider locale="en">
      <ProviderNorth theme={teamsTheme}>
        <Provider store={store}>
          <Routes>
            <Route
              exact
              path="/ReportsList"
              element={<ReportsListPageContainer />}
            ></Route>
            <Route exact path="/" element={<Welcome />} />
          </Routes>
        </Provider>
      </ProviderNorth>
    </IntlProvider>
  </Router>,
  document.getElementById('root')
);

