import axios from "axios";
import { compose } from "redux";
import { authContext } from "../auth";
import {HEEDIFY_POWERBI_SERVICES_PATH }from "../config/config"
import {HEEDIFY_PROVISIONING_SERVICES_PATH} from "../config/config"
export const saveEmbedToken = (token) => {
  return {
    type: "SAVE_EMBED_TOKEN",
    payload: {
      token,
    },
  };
};
export const saveReportInfos = (infos) => {
  return {
    type: "SAVE_REPORT_INFOS",
    payload: {
      infos,
    },
  };
};
export const saveUserInfos = (userInfos) => {
  return {
    type: "SAVE_USER_INFOS",
    payload: {
      userInfos,
    },
  };
};
export const saveUserPhoto = (userPhoto) => {
  return {
    type: "SAVE_USER_PHOTO",
    payload: {
      userPhoto,
    },
  };
};
export const setReportsList = (list) => {
  return {
    type: "SET_REPORTS_LIST",
    payload: {
      list,
    },
  };
};

export const requestEmbedToken = () => (dispatch) => {
  axios
    .get(
      `${HEEDIFY_PROVISIONING_SERVICES_PATH}/CallFlowManagement/${authContext._user.profile.tid}/default/get/ALL?userid=${authContext._user.profile.oid}`
    )
    .then((res) => {
      axios
        .post(
          HEEDIFY_POWERBI_SERVICES_PATH+"GetReportAccessToken",
          {
            reportId: res.data[Object.keys(res.data)[0]].reportId,
            workspaceId: res.data[Object.keys(res.data)[0]].workspaceId,
          }
        )
        .then((response) => {
          const tenantQueues = res.data.tenantQueues;
          dispatch(saveEmbedToken(response.data.token));
          dispatch(
            saveReportInfos({ ...response.data.reportInfos, tenantQueues })
          );
        });
    });
};
export const getReportsList = () => async (dispatch) => {
  if (authContext._user === null) {
    authContext.login();
    return;
  }

  function getAccessToken() {
    return new Promise((resolve, reject) => {
      authContext.acquireToken(
        "https://graph.microsoft.com",
        function (errDesc, token, err, tokenType) {
          if (token) {
            resolve(token);
          }
        }
      );
    });
  }
  let token = await getAccessToken();
  let scopes = await axios.get(
    HEEDIFY_PROVISIONING_SERVICES_PATH+"ScopeManagement/" +
    authContext._user.profile.tid +
    "/get/ALL?token=" +
    token
  );
  if (Object.keys(scopes.data).length == 0) {
    // meaning user is not an admin of any scope ==> we set scope to user scope
    let myProfile = await axios.get(
      HEEDIFY_PROVISIONING_SERVICES_PATH+"MyAgentProfile/" +
      authContext._user.profile.tid +
      "/" +
      authContext._user.profile.oid +
      "?token=" +
      token
    );
    scopes["data"][myProfile.data.scope] = {};
  }
  let reportsListPromises = [];
  for (var scope in scopes.data) {
    console.log(scope);
    let flowData = await axios.get(
      `${HEEDIFY_PROVISIONING_SERVICES_PATH}CallFlowManagement/${authContext._user.profile.tid}/${scope}/get/ALL?userid=${authContext._user.profile.oid}`
    );
    Object.keys(flowData.data).map((elm) => {
      reportsListPromises.push(
        new Promise((resolve, reject) => {
          axios
            .post(  HEEDIFY_POWERBI_SERVICES_PATH+"GetReportAccessToken",
              {
                reportId: flowData.data[elm].reportId,
                workspaceId: flowData.data[elm].workspaceId,
              }
            )
            .then((res) => {
              let reportObject = res.data;
              reportObject['flowConfig'] = flowData.data[elm];
              resolve(res);
            });
        })
      );
    });
  }
  Promise.all(reportsListPromises).then((results) => {
    
    const reportsListData = results
      .map((elm) => elm.data)
      .filter((element) => {
        if (Object.keys(element).length !== 0) {
          return true;
        }
      });
    dispatch(setReportsList(reportsListData));
  });
};
export const updateReport = (report) => {
  return {
    type: "UPDATE_REPORT",
    report,
  };
};
