import { Button, Flex, Header } from "@fluentui/react-northstar";
import { authContext } from "../../auth";
import Illustration from "./reportingIllustration";
import { Navigate } from 'react-router-dom';
import React from "react";

const styles = {
  illust: {
    height: "350px",
  },
};
function Welcome(props) {
  const isLogged = authContext.getCachedUser();

  return isLogged === null ? (
    <Flex column>
      <Flex hAlign="center" vAlign="center">
        <Header as="h1" content="Welcome to Heedify Reports Page" />
      </Flex>
      <Flex hAlign="center" vAlign="center">
        <Illustration style={styles.illust} />
      </Flex>
      <Flex hAlign="center" vAlign="center">
        <Button
          icon={<MsIcon />}
          content="Sign in"
          secondary
          onClick={() => {
            authContext.login().then((data) => { });
          }}
        />
      </Flex>
    </Flex>
  ) : (
    <Navigate
      to={{
          pathname: "/ReportsList",
      }}
    />
  );
  function MsIcon(props) {
    return (
      <svg viewBox="0 0 512 512" height="100%" width="100%" {...props}>
        <path d="M496 0H272v240h240V16c0-8.832-7.168-16-16-16zM16 0C7.168 0 0 7.168 0 16v224h240V0H16zM0 272v224c0 8.832 7.168 16 16 16h224V272H0zM272 272v240h224c8.832 0 16-7.168 16-16V272H272z" />
      </svg>
    );
  }
}

export default Welcome;
