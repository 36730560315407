import React, { useEffect, useState } from "react";
import { getReportsList, updateReport } from "../redux/actions";
import PowerBIReportComponent from "../components/PowerBIReportComponent";
import ReportsListComponent from "../components/reportsListComponent/reportsListComponent";
import { connect } from "react-redux";
import { Header, Loader, Flex, Layout, Button } from "@fluentui/react-northstar";
import logo from "../assets/heedify-logo.png";
import { Login } from "@microsoft/mgt-react";
const ReportsListPageContainer = (props) => {
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedReportHeaderInfo, setSelectedReportHeaderInfo] = useState();
  const [reportsLoading, setReportsLoading] = useState(false);
  function GetLogo() {
    return (
      <div className="welcome-logo">
        <img
          alt="Heedify"
          src={logo}
        ></img>
      </div>
    );
  }
  useEffect(() => {
    props.getReportsList();
  }, []);
  useEffect(() => {
    props.reportList ? setReportsLoading(false) : setReportsLoading(true);
  }, [props.reportList]);
  const selectReport = (data) => {
    setSelectedReport(data);
  };
  const setHeader = (data) => {
    setSelectedReportHeaderInfo(data)
  }
  return (
    <>
    <div style={{display:'flex'}}>
      <div style={{width:'25%', display: 'flex', alignItems: 'center' }}>
      <span>{GetLogo()}</span><span>Heedify reports portal</span>
      </div>
      <div style={{width:'50%', display: 'flex' ,justifyContent:'space-around',alignItems:'center'}}>
          <Header as="h4" content={selectedReport && selectedReport.reportInfos.name ? selectedReport.reportInfos.name : null} />
          <div className="hisorique-header-div" >
            <div >
              {selectedReportHeaderInfo  ? (
                <span style={{display:'flex'}} >Last update: {selectedReportHeaderInfo && selectedReportHeaderInfo.lastUpdate && selectedReportHeaderInfo.lastUpdate.startTime ?
                  new Date(selectedReportHeaderInfo.lastUpdate.startTime).toLocaleString() : 
                  <>
                  <div className="loader-header-historique" >
                    <Loader size="smaller" />
                  </div>
                  <span>Loading...</span>
                </>
                  }</span>
              ) : null}
            </div>
            {
              selectedReportHeaderInfo && selectedReportHeaderInfo.refreshingReport ?
                <>
                  <div className="loader-header-historique" >
                    <Loader size="smaller" />
                  </div>
                  <span>Refreshing...</span>
                </>


                :
                null
            }

          </div>
      </div>
      <div style={{width:'25%', display:'flex',justifyContent:'end',alignItems: 'center', paddingRight:'20px'}}>
        {
          /**  <Login id="login" />*/
        }
     
      </div>
    </div>
      {!selectedReport ? (
        <Flex column style={{ height: "100%", display: "grid" }}>
          <div style={{ backgroundColor: "#f5f5f5" }}>
            <Header as="h2" content="Reports list" style={{ margin: "10px" }} />
            {reportsLoading ? (
              <Loader label="fetching reports" />
            ) : (
              <div>
                <div style={{ margin: "10px" }}>
                  <ReportsListComponent
                    selectReport={selectReport}
                    reportsListData={props.reportList}
                  />
                </div>
              </div>

            )}
          </div>
        </Flex>
      ) : (
        <>
          {props.reportList ? (
            <PowerBIReportComponent
              selectReport={selectReport}
              reportInfos={selectedReport.reportInfos}
              token={selectedReport.token}
              updateReport={props.updateReport}
              flowConfig={selectedReport.flowConfig}
              setHeader={setHeader}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reportList: state.reportingActions.listOfReports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReportsList: () => dispatch(getReportsList()),
  updateReport: (report) => dispatch(updateReport(report))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsListPageContainer);
