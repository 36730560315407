import { combineReducers } from "redux";
const initState = {
  userPhoto: "",
};
function reportingActions(state = initState, action) {
  switch (action.type) {
    case "SAVE_EMBED_TOKEN":
      return {
        ...state,
        embedToken: action.payload.token,
      };

    case "SAVE_REPORT_INFOS":
      return {
        ...state,
        reportInfos: action.payload.infos,
      };
    case "SAVE_USER_INFOS":
      return {
        ...state,
        userInfos: Object.assign({}, action.payload.userInfos),
      };
    case "SAVE_USER_PHOTO":
      return {
        ...state,

        userPhoto: URL.createObjectURL(action.payload.userPhoto),
      };
    case "UPDATE_REPORT":
      return {
        ...state,
        listOfReports: state.listOfReports.map((item) =>
          item.reportInfos.id === action.report.reportInfos.id ? action.report : item
        ),
      };
      break;
    case "SET_REPORTS_LIST":

      return {
        ...state,
        listOfReports: action.payload.list,
      };
      break;
    default:
      // need this for default case
      return state;
  }
}
const reportingReducers = combineReducers({
  reportingActions,
});
export default reportingReducers;
