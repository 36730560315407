import React, { useEffect, useState } from "react";
import "../App.css";
import { Header, Flex, Button, Menu, Loader } from "@fluentui/react-northstar";
import { ArrowLeftIcon } from "@fluentui/react-icons-northstar";
import axios from "axios";
import { HEEDIFY_POWERBI_SERVICES_PATH } from "../config/config"
import StandardDisplayReport from './StandardDisplayReport'
import LegacyDisplayReport from './LegacyDisplayReport'
const PowerBIReportComponent = (props) => {
    const [powerBiRapport, setPowerBiRapport] = useState({
        reportInfos: props.reportInfos,
        token: props.token
    });
    let [refreshingReport, setRefreshingReport] = useState(false);
    let [lastUpdate, setLastUpdate] = useState();

    const refreshReport = (datasetId, groupId, reportId) => {
        const url = HEEDIFY_POWERBI_SERVICES_PATH + "RefreshReport";
        const data = {
            "datasetId": datasetId,
            "groupId": groupId
        };
        // Make the initial request to refresh the report
        axios.post(url, data).then(() => {
            // Create an interval to check if the report has been refreshed every 10 seconds
            const intervalId = setInterval(() => {
                setRefreshingReport(true)
                getReportHistoy(groupId, datasetId).then((refreshes) => {
                    let newLastRefresh = refreshes.reduce((prev, current) => {
                        const prevTimestamp = new Date(prev.startTime);
                        const currentTimestamp = new Date(current.startTime);
                        return prevTimestamp > currentTimestamp ? prev : current;
                    });
                    // Check if the report has been refreshed
                    if (newLastRefresh.status === 'Completed') {
                        setLastUpdate(newLastRefresh)
                        clearInterval(intervalId); // Stop the interval
                        setRefreshingReport(false) // stop loading refresh report 
                        axios.post(
                            HEEDIFY_POWERBI_SERVICES_PATH + "GetReportAccessToken",
                            {
                                reportId: reportId,
                                workspaceId: groupId
                            }
                        ).then((res) => {
                            props.updateReport(res.data)
                            setPowerBiRapport(res.data)
                        });
                    }
                    if (newLastRefresh.status === "Failed") {
                        setLastUpdate(newLastRefresh)
                        clearInterval(intervalId); // Stop the interval
                        setRefreshingReport(false) // stop loading refresh report 
                    }
                });
            }, 30000);
        });

    };
    const getReportHistoy = (groupId, datasetId) => {
        return new Promise((resolve, reject) => {
            let url = HEEDIFY_POWERBI_SERVICES_PATH + "ReportHistory";
            let data = {
                "datasetId": datasetId,
                "groupId": groupId
            }
            axios.post(url, data).then((result) => {
                resolve(result.data)
            })
        })
    }
    useEffect(() => {
        if (props.reportInfos && props.token) {
            let datasetId = powerBiRapport.reportInfos.datasetId
            let groupId = powerBiRapport.reportInfos.datasetWorkspaceId
            getReportHistoy(groupId, datasetId).then((history) => {
                if (history !== "") {
                    let refreshes = history.filter(obj => obj.status === 'Completed');
                    if (refreshes.length > 0) {
                        let lastRefresh = refreshes.reduce((prev, current) => {
                            const prevTimestamp = new Date(prev.endTime);
                            const currentTimestamp = new Date(current.endTime);
                            return prevTimestamp > currentTimestamp ? prev : current;
                        });
                        const currentDate = new Date();
                        const targetDate = new Date(lastRefresh.endTime);
                        setLastUpdate(lastRefresh)
                        if (((currentDate - targetDate) / 3600000) >= 1) {
                            setRefreshingReport(true);
                            refreshReport(datasetId, groupId, powerBiRapport.reportInfos.id)
                        }
                    } else {
                        refreshReport(datasetId, groupId, powerBiRapport.reportInfos.id)
                    }
                } else {
                    refreshReport(datasetId, groupId, powerBiRapport.reportInfos.id)
                }
            })
        };
    }, [props.reportInfos, props.token])
    useEffect(() => {
        props.setHeader({ refreshingReport: refreshingReport, lastUpdate: lastUpdate })
    }, [refreshingReport, lastUpdate])
    return (
        <div className="App report-mean-div"  >
            {/* PowerBI Embed Section */}
            {powerBiRapport && powerBiRapport.reportInfos && powerBiRapport.reportInfos.id ? (
                <>
                    <Button
                        icon={<ArrowLeftIcon />}
                        text
                        primary
                        onClick={() => { props.selectReport(null); props.setHeader(null) }}
                        content="Reports list"
                        style={{position:'absolute',zIndex:'1'}}
                    />
                    {
                        props.flowConfig &&
                        props.flowConfig.reportConfiguration ?
                            < StandardDisplayReport
                                powerBiRapport={powerBiRapport}
                                reportInfos={props.reportInfos}
                                token={props.token}
                                flowConfig={props.flowConfig}
                            />
                            :
                            < LegacyDisplayReport
                                powerBiRapport={powerBiRapport}
                                token={props.token}
                            />

                    }
                </>
            ) : null}
        </div>

    );
}

export default PowerBIReportComponent;
